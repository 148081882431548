$(document).ready(function() {

	// ================================================
	// Document variables
	// ================================================

	var winWidth = $(window).width();
	var winHeight = $(window).height();

	// ================================================
	// Throttled scroll check
	// ================================================

	var scrollTimeout;
	var scrollTimeout;
	var resizeTimeout;
	var throttle = 50;
	var scrollPos = $(window).scrollTop();
	var thisScrollPos;

	if (scrollPos > 0) {
		$('body').addClass('page-scrolled');
	}

	$(window).on('scroll', function () {

	    if (!scrollTimeout) {
	        scrollTimeout = setTimeout(function () {
			scrollPos = $(window).scrollTop();
			if (scrollPos > 0) {
				$('body').addClass('page-scrolled');
			} else {
				$('body').removeClass('page-scrolled');
			}
			if ($('body').hasClass('page-products') && winWidth < 768 && scrollPos > (winHeight - 100)) {
				$('#summary-sticky').addClass('is-active');
			} else {
				$('#summary-sticky').removeClass('is-active');
			}
	        scrollTimeout = null;
	        }, throttle);
	    }

	});

	$('#cat-menu-all').on('scroll', function () {

	    if (!scrollTimeout) {
	        scrollTimeout = setTimeout(function () {
			thisScrollPos = $('#cat-menu-all').scrollTop();
			if (thisScrollPos > 0) {
				$('#navbar-toggler').addClass('menu-scrolled');
			} else {
				$('#navbar-toggler').removeClass('menu-scrolled');
			}
	        scrollTimeout = null;
	        }, throttle);
	    }

	});


	$(window).on('resize', function () {
	    if (!resizeTimeout) {
	        resizeTimeout = setTimeout(function () {
			winWidth = $(window).width();
			winHeight = $(window).height();
	        resizeTimeout = null;
	        }, throttle);
	    }
	});


	// ================================================
	// Navbar toggler
	// ================================================

	$('#navbar-toggler').click(function() {
		$(this).toggleClass('mobile-nav-open');
		$('#main-nav').toggleClass('mobile-nav-open');
		$('.cat-menu-wrapper').removeClass('cat-menu-open');
	});


	// ================================================
	// Nav category menus
	// ================================================

	// Toggle second level nav on mobile
	$('.nav-has-dropdown').click(function() {
		if (winWidth < 992) {
			$(this).next().addClass('cat-menu-open');
			$('#navbar-toggler').addClass('cat-menu-open');
		}
	});

	// Nav back button on mobile
	$('.cat-menu-back').click(function() {
		$(this).closest('.cat-menu-wrapper').removeClass('cat-menu-open');
		$('#mobile-account-menu').removeClass('cat-menu-open');
		$('#navbar-toggler').removeClass('cat-menu-open');
	});

	// Show menu on hover, click works on tablet landscape
	var catMenuTimeout = false;
	$('.nav-has-dropdown').parent().hover(function(e) {
		var $target = $(e.target);
		if (winWidth > 991 && catMenuTimeout == false || $target.hasClass('nav-has-dropdown')) {
			$(this).find('.cat-menu-wrapper').addClass('cat-menu-open');
		}
	}, function() {
		if (winWidth > 991) {
			$(this).find('.cat-menu-wrapper').removeClass('cat-menu-open');
			catMenuTimeout = true;
			setTimeout(function() {
				catMenuTimeout = false;
			}, 500);
		}
	});

	// Disable dropdown parents on touch devices

	$('a.nav-has-dropdown').click(function(e) {
		if (Modernizr.touchevents) {
			e.preventDefault();
		}
	});


	// ================================================
	// Nav track order module
	// ================================================

	$('.track-order-module .btn').click(function() {
		$(this).parent().addClass('is-active');
	});


	// ================================================
	// Nav account menu
	// ================================================

	$('#mobile-nav-account').click(function() {
		$('#mobile-account-menu').closest('.cat-menu-wrapper').addClass('cat-menu-open');
	});
	$('#account-menu-toggle').click(function() {
		$('#main-account-menu').toggleClass('vis-hide');

		setTimeout(function() {
			$(document).on('click', function(event) {
				$target = $(event.target);
				if(!$target.closest('#nav-account').length &&
				$('#main-account-menu').is(":visible")) {
					$('#main-account-menu').addClass('vis-hide');
				}
			});
		}, 50);

	});


	// ================================================
	// Account orders ref hover
	// ================================================

	$('.has-reference').hover(function() {
		$(this).css('background-color', 'rgba(0, 0, 0, 0.075)');
		$(this).prev().css('background-color', 'rgba(0, 0, 0, 0.075)');
	}, function() {
		$(this).css('background-color', 'transparent');
		$(this).prev().css('background-color', 'transparent');
	});
	$('.orders-table-reference').hover(function() {
		$(this).css('background-color', 'rgba(0, 0, 0, 0.075)');
		$(this).next().css('background-color', 'rgba(0, 0, 0, 0.075)');
	}, function() {
		$(this).css('background-color', 'transparent');
		$(this).next().css('background-color', 'transparent');
	});


	// ================================================
	// Account invite contact
	// ================================================

	$('#invite-contact-modal-prompt button').click(function(e) {
		e.preventDefault();
		$('#invite-contact-modal-body').height($('#invite-contact-modal-body').height());
		$('#invite-contact-modal-prompt').fadeOut(function() {
			$('#invite-contact-modal-thank-you').fadeIn();
		});
	});


	// ================================================
	// Nav Ways to Buy
	// ================================================

	$('.ways-nav-item').click(function() {
		$('.ways-nav-item').removeClass('is-active');
		$(this).addClass('is-active');
	});


	// ================================================
	// Testimonials
	// ================================================

	var homeTestimonialHeight = $('#home-testimonials-slider').height() + 'px';

	$('#home-testimonials-slider .carousel-inner').css('height', homeTestimonialHeight);

	$('#home-testimonials-slider').on('slide.bs.carousel', function (e) {
		homeTestimonialHeight = $(this).find('.carousel-inner').children().eq(e.to).height() + 'px';
		$(this).find('.carousel-inner').css('height', homeTestimonialHeight);
	});


	// ================================================
	// Cart
	// ================================================

	// Cart item action menu
	$('.cart-item-toggler').click(function() {
		$(this).next().fadeToggle();
	});

	// Cart Steps navigation
	var thisCartItem;
	var thisCartItemSteps;
	var cartStepActive;
	var thisCartItemButtons;

	// Set all step container heights to their current step
	$('.cart-item').each(function() {
		cartStepActive = $(this).find('.cart-item-step.is-active');
		thisCartItemSteps = $(this).find('.cart-item-steps');
		thisCartItemButtons = $(this).find('.cart-item-step-buttons');

		thisCartItemSteps.css('height', (cartStepActive.outerHeight() + 'px'));
	});

	// Next cart step
	$('.cart-item-step-next, .cart-item-step-skip').click(function() {

		// Set vars (clicked cart item & active step)
		thisCartItem = $(this).closest('.cart-item');
		thisCartItemSteps = thisCartItem.find('.cart-item-steps');
		thisCartItemButtons = thisCartItem.find('.cart-item-step-buttons');
		thisCartItemProgress = thisCartItem.find('.cart-item-progress');
		cartStepActive = thisCartItemSteps.find('.cart-item-step.is-active');

		// Fade out and remove class
		cartStepActive.removeClass('is-active').fadeOut();

		// Update progress bar
		if (cartStepActive.hasClass('cart-item-step-name')) {
			thisCartItemProgress.addClass('name-complete editing-proof');
			thisCartItemProgress.removeClass('editing-name');
		} else if (cartStepActive.hasClass('cart-item-step-proof')) {
			thisCartItemProgress.addClass('proof-complete editing-shipping');
			thisCartItemProgress.removeClass('editing-proof');
		} else if (cartStepActive.hasClass('cart-item-step-shipping')) {
			thisCartItemProgress.addClass('shipping-complete editing-files');
			thisCartItemProgress.removeClass('editing-shipping');
		} else if (cartStepActive.hasClass('cart-item-step-files')) {
			thisCartItemProgress.addClass('files-complete');
			thisCartItemProgress.removeClass('editing-files');
		}

		// Change var, fade in, add class
		cartStepActive = cartStepActive.next();
		cartStepActive.fadeIn().addClass('is-active');

		// Change buttons if necessary
		if (cartStepActive.is(':last-child')) {
			thisCartItemButtons.find('.cart-item-step-next').hide();
			thisCartItemButtons.find('.cart-item-step-save').show();
		}

		// Animate steps container to adapt to height
		thisCartItemSteps.animate({height: cartStepActive.outerHeight()}, 500);
	});

	// Prev cart step
	$('.cart-item-step-back').click(function() {

		// Set vars (clicked cart item & active step)
		thisCartItem = $(this).closest('.cart-item');
		thisCartItemSteps = thisCartItem.find('.cart-item-steps');
		thisCartItemButtons = thisCartItem.find('.cart-item-step-buttons');
		cartStepActive = thisCartItemSteps.find('.cart-item-step.is-active');

		// Fade out and remove class
		cartStepActive.removeClass('is-active').fadeOut();

		// Change buttons if necessary
		if (cartStepActive.is(':last-child')) {
			thisCartItemButtons.find('.cart-item-step-next').show();
			thisCartItemButtons.find('.cart-item-step-save').hide();
		}

		// Change var, fade in, add class
		cartStepActive = cartStepActive.prev();
		cartStepActive.fadeIn().addClass('is-active');

		// Animate steps container to adapt to height
		thisCartItemSteps.animate({height: cartStepActive.outerHeight()}, 500);
	});


	// ================================================
	// Slick Sliders
	// ================================================

	$('#hero-slider-standard').slick({
		arrows: false,
		autoplaySpeed: 4500,
		autoplay: true,
		fade: true
	});
	$('#hero-slider-standard').on('beforeChange', function(event, slick, currentSlide, nextSlide){
	  $('#hero-slider-standard-thumbs .slick-slide').removeClass('slick-active');
	  $('#hero-slider-standard-thumbs .slick-slide').eq(nextSlide).addClass('slick-active');;
	});
	$('#hero-slider-standard-thumbs').slick({
		arrows: false,
		asNavFor: '#hero-slider-standard',
		focusOnSelect: true,
		slidesToShow: 4
	});
	$('#hero-slider-standard-thumbs').on('afterChange', function(event, slick, currentSlide, nextSlide){
	  $('#hero-slider-standard-thumbs .slick-slide').removeClass('slick-active');
	  $('#hero-slider-standard-thumbs .slick-slide').eq(currentSlide).addClass('slick-active');;
	});

	$('#hero-slider-custom').slick({
		arrows: false,
		autoplaySpeed: 4500,
		autoplay: true,
		fade: true
	});
	$('#hero-slider-custom').on('beforeChange', function(event, slick, currentSlide, nextSlide){
	  $('#hero-slider-custom-thumbs .slick-slide').removeClass('slick-active');
	  $('#hero-slider-custom-thumbs .slick-slide').eq(nextSlide).addClass('slick-active');;
	});
	$('#hero-slider-custom-thumbs').slick({
		arrows: false,
		asNavFor: '#hero-slider-custom',
		focusOnSelect: true,
		slidesToShow: 4
	});
	$('#hero-slider-custom-thumbs').on('afterChange', function(event, slick, currentSlide, nextSlide){
	  $('#hero-slider-custom-thumbs .slick-slide').removeClass('slick-active');
	  $('#hero-slider-custom-thumbs .slick-slide').eq(currentSlide).addClass('slick-active');;
	});

	$('#hero-slider-standard-thumbs .slick-slide, #hero-slider-custom-thumbs .slick-slide').removeClass('slick-active');
	$('#hero-slider-standard-thumbs .slick-slide:first-child, #hero-slider-custom-thumbs .slick-slide:first-child').addClass('slick-active');

	$('#case-study-slider').slick({
		arrows: false,
		autoplay: true,
		fade: true,
		pauseOnFocus: false,
		pauseOnHover: false
	});


	// ================================================
	// Product Image Zoom
	// ================================================

	$('.product-image.product-has-image').click(function() {
		$('#product-zoom-modal').modal();
	});


	// ================================================
	// Sticky Product Summary
	// ================================================

	$('.product-image.product-has-image').click(function() {
		$('#product-zoom-modal').modal();
	});


	// ================================================
	// Video Page Modals
	// ================================================

	var vimeoMP4;

	$('.video-grid-item').click(function() {
		vimeoMP4 = $(this).attr('data-vimeo-link');
		console.log(vimeoMP4);
		$('#vimeo-embed-wrapper').html('<video autoplay controls class="embed-responsive-item" id="video-modal-video"><source src="'+vimeoMP4+'" type="video/mp4"></video>');
		$('#video-modal').modal();
	});
	$('#video-modal').on('hide.bs.modal', function (e) {
		document.getElementById('video-modal-video').pause();

	});


	// ================================================
	// Inline SVG
	// ================================================
	$('img.svg').each(function(){
	    var $img = $(this);
	    var imgID = $img.attr('id');
	    var imgClass = $img.attr('class');
	    var imgURL = $img.attr('src');

	    $.get(imgURL, function(data) {
	        // Get the SVG tag, ignore the rest
	        var $svg = $(data).find('svg');

	        // Add replaced image's ID to the new SVG
	        if(typeof imgID !== 'undefined') {
	            $svg = $svg.attr('id', imgID);
	        }
	        // Add replaced image's classes to the new SVG
	        if(typeof imgClass !== 'undefined') {
	            $svg = $svg.attr('class', imgClass+' replaced-svg');
	        }

	        // Remove any invalid XML tags as per http://validator.w3.org
	        $svg = $svg.removeAttr('xmlns:a');

	        // Replace image with new SVG
	        $img.replaceWith($svg);

	    }, 'xml');

	});



	$('#template-select').on('change', function() {
		location.href = "/categories/" + this.value + "/templates";
	});

	$('#order-sort').on('change', function() {
		$.ajax({
	      url: "/orders",
	      type: "get",
	      dataType: "script",
				data: {
					filter: this.value
				}
	    });
	});

	$("#edit-reference-btn").on('click', function() {
		$("#order-detail-ref").addClass("d-none");
		$("#edit-reference-form").removeClass("d-none");
	});

	$("#place-order-form").submit(function(e){
		if($('#place_order_agree_to_terms').is(':checked')){
			$("#place-order-btn").prop("disabled",true);
			$("#place-order-btn span").removeClass("d-none");
			return true;
		}
		$('#error-modal #error-message').html('You must agree to the terms of use.');
		$('#error-modal').modal('show');
		return false;
	});

	$("#new_new_account").submit(function(e){
		if($('#new_account_agree').is(':checked')){
			return true;
		}
		$('#error-modal #error-message').html('You must agree to the terms of use.');
		$('#error-modal').modal('show');
		return false;
	});


});
