import Rails from '@rails/ujs';
Rails.start();
global.Rails = Rails;

var jQuery = require('jquery')

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import './bootstrap_custom.js'

import './slick.min.js'
import './modernizr-custom.js'
import './main.js'

import '@fortawesome/fontawesome-free/js/all.min.js'

import '../stylesheets/americasprinter/application'
